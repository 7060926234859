import modalsCommon from '../modals';

const recovery = {
    hash: undefined,
    initEvent() {
        $('.js--recovery').click(() => {
            $('.modal').removeClass('active');
            $('.js--modal_recovery').addClass('active');
        });
    },
    // Отправка почты с запросом на восстановление
    submit() {
        const $form = $('#recovery');
        const data = modalsCommon.getDataFrom($form, ['email']);
        $form.find('button:submit').prop('disabled', true);
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            contentType: 'application/json',
            data: JSON.stringify(data),
            success(rsp) {
                if (rsp.status === 'ok') {
                    $form.find('button:submit').prop('disabled', false);
                    $('.modal.active').removeClass('active');
                    $('.js--modal_password_on_mail').addClass('active');
                    $('#recovery').find('input, textarea').val('');
                } else {
                    recovery.successMsgHandler($form, rsp);
                }
            },
            error(rsp) {
                recovery.errorMsgHandler($form, rsp);
            },
        });
    },
    // Сохранение хеша если он есть в URL
    saveHashIfExist() {
        const isRunning = window.location.pathname.includes('/reset_password');
        const recoveryHash = window.location.search
            .split('&')
            .filter(str => str.includes('hash'))
            .map((couple) => {
                return typeof couple === 'string' ? couple.split('=')[1] : [];
            })[0];
        recovery.hash = (isRunning && recoveryHash) ? recoveryHash : false;
        return isRunning && recoveryHash;
    },
    // Проверка Хеша
    checkRecoveryHash() {
        $('.js--modal_new_password').addClass('active');
        $('.js--modal_new_password .loader').removeClass('hide');
        $.ajax({
            url: '/api/1/password/check',
            type: 'GET',
            contentType: 'application/json',
            data: { hash: recovery.hash },
            success(rsp) {
                if (rsp.status === 'ok') {
                    $('.js--modal_new_password .modal_container__guts').addClass('show_fields');
                } else {
                    recovery.hashErrorHandler(rsp);
                }
            },
            error(rsp) {
                recovery.hashErrorHandler(rsp);
            },
        });
    },
    // Отправка нового пароля
    setNewPassword() {
        const $form = $('#new_password');
        const newPassword = $form.find('#forms_new_password_password').val();
        const data = { password: newPassword, hash: recovery.hash };
        $form.find('button:submit').prop('disabled', true);
        $.ajax({
            url: '/api/1/password/change',
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(data),
            success(rsp) {
                if (rsp.status === 'ok') {
                    $('.js--modal_new_password .modal_container__guts').removeClass('show_fields');
                    $('.js--modal_new_password .loader').addClass('hide');
                    $('.js--modal_new_password .response_messages')
                        .text(rsp.message)
                        .addClass('show');
                } else {
                    recovery.successMsgHandler($form, rsp);
                }
            },
            error(rsp) {
                recovery.errorMsgHandler($form, rsp);
            },
        });
    },
    hashErrorHandler(rsp) {
        const error = rsp.responseJSON.error.message; // 404
        const $modal = $('.js--modal_new_password');
        $modal.find('.loader').addClass('hide');
        $modal.find('.response_messages')
            .text(error)
            .addClass('show');
    },
    successMsgHandler($form, rsp) {
        const err = rsp.errors[0].message;
        const $inputs = $form.find('.form_input');
        $form.find('button:submit').prop('disabled', false);
        $inputs.addClass('wrapper_error');
        $inputs.find('.form_input__error').append((`<span class="error">${err}</span>`))
            .show();
    },
    errorMsgHandler($form, rsp) {
        const err = JSON.parse(rsp.responseText).error.message;
        const $inputs = $form.find('.form_input');
        $form.find('button:submit').prop('disabled', false);
        $inputs.addClass('wrapper_error');
        $inputs.find('.form_input__error').append((`<span class="error">${err}</span>`))
            .show();
    },
};

export default recovery;
