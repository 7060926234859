import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn: 'https://ddf9bf6677e64ce48801cc9b57fc71a5@sentry.forforce.com/12',

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    release: 'redmine',
    integrations: [
        new Sentry.BrowserTracing({
            // eslint-disable-next-line max-len
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['*'],
        }),
        new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});
