$(document).ready(() => {
    function searchBlog(searchValue) {
        const form = $('#search');
        clearTimeout(window.myTimeout);
        window.myTimeout = setTimeout(() => {
            $.ajax({
                url: form.attr('action'),
                type: 'post',
                data: `search=${searchValue}`,
                beforeSend() {
                    $('#loading').show();
                    $('.articles_wrap .articles').remove();
                },
                success(rsp) {
                    $('.articles_wrap').append(rsp);
                },
                complete() {
                    $('#loading').hide();
                    if (searchValue.length === 0) {
                        $('.js--navigation').show();
                    } else {
                        $('.js--navigation').hide();
                        $('#search .close_search').css('opacity', '1');
                    }
                },
                error: (rsp) => {
                    console.log(rsp);
                },
            });
        }, 1000);
    }

    $('#forms_search_search').on('keyup paste', (e) => {
        const searchValue = e.target.value;
        if (searchValue.length >= 3) {
            searchBlog(searchValue);
        }
        if (searchValue.length === 0) {
            searchBlog(searchValue);
        }
    });
    $('#search .close_search').on('click', () => {
        $('#forms_search_search').val('');
        searchBlog('');
        $('#search .close_search').css('opacity', '0');
    });
    function ratePost(rate) {
        const form = $('#ratingForm');
        const data = {
            rating: rate,
            article: $('#ratingForm input[name="article"]').val(),
            gRecaptchaResponse: $('#ratingForm input[name="gRecaptchaResponse"]').val(),
        };
        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            contentType: 'application/json',
            data: JSON.stringify(data),
            success(rsp) {
                $('#ratingForm #calculatedRating').text(rsp.calculatedRating);
                $('#ratingForm #rateCount').text(rsp.count);
                $('.rating input[name="rating"]').attr('disabled', true);
            },
            error: (rsp) => {
                console.log(rsp);
            },
        });
    }
    $('#ratingForm input[name="rating"]').on('change', (e) => {
        $('#ratingForm input[name="rating"]').attr('disabled', true);
        const rate = e.target.value;
        grecaptcha.ready(() => {
            grecaptcha.execute('6LcWsAAVAAAAAJYdSxYcw99WcFq_N3hQgr9V-D5X', { action: 'submit' }).then((token) => {
                $('#ratingForm input[name="gRecaptchaResponse"]').val(token);
                ratePost(rate);
            });
        });
    });
    $('.js--relatedPost__slider').slick({
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        appendDots: $('.slider_slick_dots'),
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
});
