$(document).ready(() => {
    $('.js_btn_favorite').click(function () {
        $(this).toggleClass('active');
        $(this).parents('.js_task_timer').find('.js_task_favorite').toggleClass('inline-block');
        const id = $(this).parents('.js_task_timer').attr('data-taskid');
        $(`.popup_edit_task[data-editid="${id}"]`).find('.js_task_favorite').toggleClass('inline-block');
        setTimeout(() => {
            sort_tasks();
        }, 100);
    });

    function sort_tasks() {
        const priority_array = [];
        let count = 0;
        $('.js_task_list .task').each(function () {
            priority_array[count] = [];
            priority_array[count].elem = $(this);
            if ($(this).find('.js_task_priority').text() == 'Immediate') { priority_array[count].priority = 5; }
            if ($(this).find('.js_task_priority').text() == 'Urgent') { priority_array[count].priority = 4; }
            if ($(this).find('.js_task_priority').text() == 'High') { priority_array[count].priority = 3; }
            if ($(this).find('.js_task_priority').text() == 'Normal') { priority_array[count].priority = 2; }
            if ($(this).find('.js_task_priority').text() == 'Low') { priority_array[count].priority = 1; }
            if ($(this).find('.js_task_favorite').hasClass('inline-block')) { priority_array[count].star = 1; } else { priority_array[count].star = 0; }
            if ($(this).hasClass('current')) { priority_array[count].current = 1; } else { priority_array[count].current = 0; }
            count++;
        });
        setTimeout(() => {
            priority_array.sort((a, b) => {
                if (a.current < b.current) return 1;
                if (a.current > b.current) return -1;
                if (a.star < b.star) return 1;
                if (a.star > b.star) return -1;
                if (a.priority < b.priority) return 1;
                if (a.priority > b.priority) return -1;
                return 0;
            });

            for (let i = 0; i < priority_array.length; i++) {
                if (i == 0) {
                    if ($(priority_array[i].elem).index() != 0) {
                        $(priority_array[i].elem).prependTo($('.js_task_list .jspPane'));
                        // $(priority_array[i].elem).insertBefore($('.task').first());
                    }
                } else {
                    $(priority_array[i].elem).insertAfter(priority_array[i - 1].elem);
                }
            }
        }, 100);
    }

    $('#cc9910').on('change', function () {
        if ($(this).prop('checked') == true) {
            $('.task[data-taskid=3]').addClass('none');
            $('.task[data-taskid=4]').addClass('none');
            $('.task[data-taskid=5]').addClass('none');
            $('.js_number_tasks_show').text('2');
            $('.js_number_tasks_hidden').text('0');
        } else {
            $('.task[data-taskid=3]').removeClass('none');
            $('.task[data-taskid=4]').removeClass('none');
            $('.task[data-taskid=5]').removeClass('none');
            $('.js_number_tasks_show').text(task_timer.length - $('.js_task_list .js_task_timer.non_search').length);
            $('.js_number_tasks_hidden').text($('.js_task_list .js_task_timer.non_search').length);
        }
        initScrollPane();
    });

    $('.js_btn_stop_t').click(() => {
        setTimeout(() => {
            sort_tasks();
        }, 100);
    });


    $('.js_btn_filter_reset').click(() => {
        setTimeout(() => {
            sort_tasks();
        }, 10);
    });


    $('.js_btn_hide_popup').click(() => {
        // console.log($(this).parents('.popup_edit_task'));
        setTimeout(() => {
            sort_tasks();
        }, 100);
    });

    $('.js_need_reg').on('click', () => {
        hide_client();
    });

    $('.js_load_file').on('click', function () {
        // $('.popup_edit_task').css('height', 555);
        $('.attaching_file').removeClass('none');
        const load_progress = $(this).parents('.js_timer_comment_edit').find('.js_file_progress');
        const file_name = $(this).parents('.js_timer_comment_edit').find('.js_file_name');
        let count = 1;
        for (let i = 1; i <= 100; i++) {
            setTimeout(() => {
                $(load_progress).html(`${count}%`);
                count++;
                if (count == 100) {
                    $(file_name).html('File loaded!');
                    setTimeout(() => {
                        $(load_progress).html('');
                    }, 100);
                }
            }, i * 50);
        }
    });

    $('.js_remove_file').on('click', function () {
        $(this).parents('.js_timer_comment_edit').find('.js_file_name').html('Loading file...');
        $(this).parents('.js_timer_comment_edit').find('.js_file_progress').html('');
        $('.attaching_file').addClass('none');
        $('.popup_edit_task').removeAttr('style');
    });

    const rm_client_timer = $('#rm_client_timer');
    if (rm_client_timer) {
        //        инициализируем скролл
        function initScrollPaneLog() {
            $('.js_rm_login_cont').jScrollPane({
                verticalGutter: 5,
            });
        }
        function initScrollPaneEditTask() {
            $('.js--popup_edit_task__inner').jScrollPane({
                verticalGutter: 5,
            });
        }

        function initScrollPane() {
            $('.js_task_list').jScrollPane({
                verticalDragMaxHeight: 310,
                verticalGutter: 5,
                // showArrows: true,
            });
        }
        initScrollPane();

        // let timer;
        // $('.js_task_list').on('scroll', () => {
        //     $('.js_task_list .jspVerticalBar').addClass('show');
        //     clearTimeout(timer);
        //     timer = setTimeout(() => {
        //         $('.js_task_list .jspVerticalBar').removeClass('show');
        //     }, 1000);
        // });


        //        инициализируем скролл

        var task_timer = $('.js_task_timer');
        const timer_field = $('.js_timer_search_field');
        const filter_field = $('.js_timer_filter');
        const popup_time_task = $('.js_popup_time_task');
        const popup_time = $('.js_popup_time');

        let rmTimeOut;
        let rmTimeOut2;
        let popupTimeOutCount;
        let popupTime;
        let timeInt;
        let timeInt2;
        let popupTimeCount;
        //        начать задачу по клику на кнопку
        task_timer.find('.js_btn_play_t').on('click', function () {
            clearAllTime();
            const current_timer_block = $(this).parents('.js_task_timer');
            const rm_time_small = current_timer_block.find('.time_small');
            const rm_time_large = current_timer_block.find('.time_large');

            task_timer.removeClass('current');
            current_timer_block.addClass('current');
            if ($('.js_task_list .jspPane')) {
                current_timer_block.prependTo($('.js_task_list .jspPane'));
            } else {
                current_timer_block.prependTo($('.js_task_list'));
            }
            $('.js_timer_comment').removeClass('block');

            $('.js_list_category').removeClass('block');
            $('.js_list_statuses').removeClass('block');
            $('.js_current_category').removeClass('active');
            $('.js_current_status').removeClass('active');
            task_timer.removeClass('pb0');

            playTimer(rm_time_large, rm_time_small);
            // show_popup(rm_time_large, rm_time_small);
            initScrollPane(); // переинициализация скролла
        });
        //      Остановить задачу
        task_timer.find('.js_btn_stop_t').click(function () {
            const current_timer_block = $(this).parents('.js_task_timer');
            const rm_time_large = current_timer_block.find('.time_large');
            current_timer_block.removeClass('current');
            $('.js_timer_comment').removeClass('block');
            clearAllTime();
            rm_time_large.html(secondsToHms(0));
            rm_time_large.attr('data-time', 0);
            $('.js_list_category').removeClass('block');
            $('.js_list_statuses').removeClass('block');
            $('.js_current_category').removeClass('active');
            $('.js_current_status').removeClass('active');

            if (current_timer_block.hasClass('non_search')) {
                current_timer_block.appendTo($('.js_task_list .jspPane'));
            }

            task_timer.removeClass('pb0');
            initScrollPane(); // переинициализация скролла
        });

        //    .parents(".js_task_timer").find(".js_timer_comment_edit").addClass("show_edit");
        //        начать задачу по двойному клику на задачу
        task_timer.on('dblclick', function () {
            if (!$(this).hasClass('non_search') & !$(this).find('.js_timer_comment_edit').hasClass('show_edit')) {
                const current_timer_block = $(this);
                const rm_time_small = current_timer_block.find('.time_small');
                const rm_time_large = current_timer_block.find('.time_large');
                $('.js_timer_comment').removeClass('block');
                if ($(this).hasClass('current')) {
                    $(this).removeClass('current');
                    clearAllTime();
                    rm_time_large.html(secondsToHms(0));
                    rm_time_large.attr('data-time', 0);
                    rm_time_small.html(secondsToHms(rm_time_small.attr('data-time')));
                } else {
                    task_timer.removeClass('current');
                    current_timer_block.addClass('current');
                    if ($('.js_task_list .jspPane')) {
                        current_timer_block.prependTo($('.js_task_list .jspPane'));
                    } else {
                        current_timer_block.prependTo($('.js_task_list'));
                    }
                    clearAllTime();
                    playTimer(rm_time_large, rm_time_small);
                    // show_popup(rm_time_large, rm_time_small);
                }
                $('.js_list_category').removeClass('block');
                $('.js_list_statuses').removeClass('block');
                $('.js_current_category').removeClass('active');
                $('.js_current_status').removeClass('active');
                task_timer.removeClass('pb0');
                initScrollPane(); // переинициализация скролла
            }
        });

        $('.js_btn_hide_popup').click(function () {
            $('.js_timer_comment_edit').find('.js_file_name').html('Loading file...');
            $('.js_timer_comment_edit').find('.js_file_progress').html('');
            $('.attaching_file').addClass('none');
            $('.popup_edit_task').removeAttr('style');

            const index_task = $(this).parents('.popup_edit_task').attr('data-editId');
            $(this).parents('.popup_edit_task').removeClass('show_edit');
            const newTime = $(`[data-editId=${index_task}]`).find('.hrs_inp input').val();
            const hrs = newTime.split('.')[0];
            let mnt = newTime.split('.')[1];
            if (!(mnt == undefined) & (newTime > 0)) {
                if (mnt.length > 1) {
                    if (mnt < 17) {
                        mnt = `0${Math.round(mnt * 0.6)}`;
                    } else if (mnt < 100) {
                        mnt = Math.round(mnt * 0.6);
                    } else {
                        mnt = mnt.substr(0, mnt.length - 1);
                        if (mnt.length > 1) {
                            if (mnt < 17) {
                                mnt = `0${Math.round(mnt * 0.6)}`;
                            } else if (mnt < 100) {
                                mnt = Math.round(mnt * 0.6);
                            }
                        }
                    }
                } else {
                    mnt += '0';
                    if (mnt < 17) {
                        mnt = `0${Math.round(mnt * 0.6)}`;
                    } else {
                        mnt = Math.round(mnt * 0.6);
                    }
                }
                $(this).parents('.task').find('.time_small_all').text(`${hrs}:${mnt}`);
            } else if (newTime > 0) {
                $(this).parents('.task').find('.time_small_all').text(`${newTime}:00`);
            }
            $(this).parents('.popup_edit_task').find('.rm_cl_txt_r').val('');

            $('.title').focus();
            $('.datepiker_input').blur();
            $('.edit_textarea').blur();
        });

        // validation input max page
        $('.hrs_inp input').keypress(function (event) {
            const currentHours = $(this).val().length;
            const currentVal = $(this).val();
            if (currentHours >= 2) {
                if ((currentVal.indexOf('.') + 1 == false)) {
                    if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27
                        //  Ctrl+A
                        || (event.keyCode == 65 && event.ctrlKey === true)
                        //  home, end, left, right
                        || (event.keyCode >= 35 && event.keyCode <= 39) || (event.keyCode == 190) || (event.keyCode == 110)) {

                    } else {
                        event.preventDefault();
                    }
                } else if (currentHours >= 5) {
                    event.preventDefault();
                }
            }
        });


        // validation input page
        $('.hrs_inp input').keydown((event) => {
            //  backspace, delete, tab и escape
            if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27
                //  Ctrl+A
                || (event.keyCode == 65 && event.ctrlKey === true)
                //  home, end, left, right
                || (event.keyCode >= 35 && event.keyCode <= 39) || (event.keyCode == 190) || (event.keyCode == 110)) {

            } else if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
                event.preventDefault();
            }
        });


        $('.js_btn_open_comment').click(function () {
            if ($(this).parents().hasClass('js_client_frame') == true) {
                return false;
            }
            $(() => {
                initScrollPaneEditTask();
                $('.datepiker_input').prop('disabled', false);
                $('#date_start1, #date_end1, #date_start2, #date_end2, #date_start3, #date_end3, #date_start4, #date_end4, #date_start5, #date_end5').datepicker({
                    showButtonPanel: false,
                    firstDay: 0,
                    weekHeader: 'Week',
                    dateFormat: 'yy-mm-dd',
                    currentText: 'Today',
                    beforeShow(input, inst) {
                        const offset = $(input).offset();
                        const height = $(input).height();
                        window.setTimeout(() => {
                            inst.dpDiv.css({ top: `${offset.top + height - 200}px`, left: `${offset.left - 91}px` });
                        }, 1);
                    },
                    closeText: 'Close',
                    dayNamesShort: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
                    monthNames: ['January -', 'February -', 'March -', 'April -', 'May -', 'June -', 'July -', 'August -', 'September -', 'October -', 'November -', 'December -'],
                });
                $('.js--show_description').on('click', (e) => {
                    e.preventDefault();
                    $('.js--edit_description').addClass('active');
                    $('.js--show_description').addClass('hide');
                    initScrollPaneEditTask();
                });
            });
            // eslint-disable-next-line no-underscore-dangle
            $.datepicker._gotoToday = function (id) {
                const target = $(id);
                // eslint-disable-next-line no-underscore-dangle
                const inst = this._getInst(target[0]);
                // eslint-disable-next-line no-underscore-dangle
                if (this._get(inst, 'gotoCurrent') && inst.currentDay) {
                    inst.selectedDay = inst.currentDay;
                    inst.drawMonth = inst.selectedMonth = inst.currentMonth;
                    inst.drawYear = inst.selectedYear = inst.currentYear;
                } else {
                    const date = new Date();
                    inst.selectedDay = date.getDate();
                    inst.drawMonth = inst.selectedMonth = date.getMonth();
                    inst.drawYear = inst.selectedYear = date.getFullYear();
                    // the below two lines are new
                    this._setDateDatepicker(target, date);
                    this._selectDate(id, this._getDateDatepicker(target));
                }
                this._notifyChange(inst);
                this._adjustDate(target);
            };
            $('#date_start1, #date_start2, #date_start3, #date_start4, #date_start5').datepicker('setDate', '0');

            const index_task = $(this).parents('.task').attr('data-taskId');
            $(`[data-editId=${index_task}]`).addClass('show_edit');
            const timeNow = $(this).parents('.js_task_timer').find('.time_now').text();
            $(`[data-editId=${index_task}]`).find('.time_small_popup').text(timeNow);
            //            $(this).parents(".js_task_timer").find("+ .js_timer_comment_edit").addClass("show_edit");
            const estimateTime = $(this).parents('.js_task_timer').find('.time_small_all').text();
            const hrs = estimateTime.split(':')[0];
            let mnt = estimateTime.split(':')[1];
            mnt = Math.round(mnt / 0.6);
            $(this).parents('.js_task_timer').find('.hrs_inp input').val(`${hrs}.${mnt}`);
        });

        $('.js_btn_close_comment').click(function () {
            $(this).parents('.js_task_timer').find('.js_timer_comment').removeClass('block')
                .addClass('none');
        });
        // реализация поиска
        function searchclick() {
            $('.js_timer_comment').removeClass('block');
            $('.js_no_filter_result').removeClass('top_small');

            if ($('.js_rm_client_timer_login').hasClass('show')) {
                $('.js_rm_client_timer_login').animate({ opacity: 0 }, 300, function () {
                    $(this).removeClass('show');
                });
            }
            if (filter_field.hasClass('block')) {
                filter_field.removeClass('block').addClass('none');
                $('.js_task_list').css({ height: $('.js_task_list').height() + 340 });
            }
            if (timer_field.hasClass('none')) {
                timer_field.removeClass('none').addClass('block');
                timer_field.find('.timer_field').focus();
                const searchWorld = $('.js_timer_field').val();
                if ($('.js_timer_field').val().length) {
                    task_timer.addClass('non_search');
                    $(`.js_task_name:contains(${searchWorld})`).parents(task_timer).removeClass('non_search');
                    $(`.js_task_number:contains(${searchWorld})`).parents(task_timer).removeClass('non_search');
                    $(`.js_task_project:contains(${searchWorld})`).parents(task_timer).removeClass('non_search');
                    if ($('.js_task_list .jspPane').length) {
                        task_timer.filter('.non_search').appendTo($('.js_task_list .jspPane'));
                        task_timer.filter('.current').prependTo($('.js_task_list .jspPane'));
                    } else {
                        task_timer.filter('.non_search').appendTo($('.js_task_list'));
                        task_timer.filter('.current').prependTo($('.js_task_list'));
                    }
                    if ($('.js_task_timer.non_search').length == $('.js_task_timer').length) {
                        $('.js_rm_client_timer').addClass('notfound');
                    } else {
                        $('.js_rm_client_timer').removeClass('notfound');
                    }
                }
                $('.js_task_list').css({ height: $('.js_task_list').height() - 32 });
            } else {
                timer_field.removeClass('block').addClass('none');
                $('.js_rm_client_timer').removeClass('notfound');
                task_timer.removeClass('non_search');
                $('.js_timer_field').val('');

                $('.js_task_list').css({ height: $('.js_task_list').height() + 32 });
            }
            initScrollPane(); // переинициализация скролла
        }

        $('.js_btn_timer_search').click(() => {
            searchclick();
        });
        // конец реализации поиска

        function filterclick() {
            if ($('.js_rm_client_timer_login').hasClass('show')) {
                $('.js_rm_client_timer_login').animate({ opacity: 0 }, 300, function () {
                    $(this).removeClass('show');
                });
            }
            if (filter_field.hasClass('none')) {
                if (timer_field.hasClass('block')) {
                    timer_field.removeClass('block').addClass('none');
                    $('.js_task_list').css({ height: $('.js_task_list').height() + 26 });
                }
                filter_field.removeClass('none').addClass('block');
                $('.js_task_list').css({ height: $('.js_task_list').height() - 344 });
                $('.js_no_filter_result').addClass('top_small');
            } else {
                filter_field.removeClass('block').addClass('none');
                $('.js_task_list').css({ height: $('.js_task_list').height() + 344 });
                $('.js_no_filter_result').removeClass('top_small');
            }
            initScrollPane(); // переинициализация скролла
        }

        $('.js_btn_filter').click(() => {
            filterclick();
        });

        $('.js_btn_close_filter').on('click', () => {
            filter_field.removeClass('block').addClass('none');
            $('.js_rm_client_timer').removeClass('notfound');
            //            task_timer.removeClass("non_search");
            $('.js_timer_field').val('');
            $('.js_task_list').css({ height: $('.js_task_list').height() + 344 });
            $('.js_no_filter_result').addClass('none');
            $('.js_no_filter_result').removeClass('top_small');


            initScrollPane(); // переинициализация скролла
        });

        $('.js_btn_close_ts').click(() => {
            timer_field.removeClass('block').addClass('none');
            task_timer.removeClass('non_search');
            $('.js_timer_field').val('');
            $('.js_task_list').css({ height: $('.js_task_list').height() + 32 });
            $('.js_rm_client_timer').removeClass('notfound');
            $('.js_no_filter_result').addClass('none');
            $('.js_no_filter_result').removeClass('top_small');

            $('.js_number_tasks_show').text(task_timer.length);
            $('.js_number_tasks_hidden').text('0');
            $('.js_select_filter').text('*Any*');
            $('.js_btn_basket').addClass('none');
            initScrollPane(); // переинициализация скролла
        });
        // кнопка "обновить", еффект вращения
        $('.js_btn_update').click(function () {
            if ($('.js_rm_client_timer_login').hasClass('show')) {
                $('.js_rm_client_timer_login').animate({ opacity: 0 }, 300, function () {
                    $(this).removeClass('show');
                    // $('.js_rm_client_timer .js_btn_update').animate({ fontSize: 180 }, {
                    //     step(now, fx) {
                    //         $(this).css('-webkit-transform', `rotate(${now}deg)`);
                    //         $(this).css('-moz-transform', `rotate(${now}deg)`);
                    //         $(this).css('-ms-transform', `rotate(${now}deg)`);
                    //         $(this).css('-o-transform', `rotate(${now}deg)`);
                    //         $(this).css('transform', `rotate(${now}deg)`);
                    //     },
                    // }, 300);
                    setTimeout(() => {
                        rotate($('.update_icon'), 0);
                        function rotate(object, degrees) {
                            object.css({
                                'font-size': 0,
                                '-webkit-transform': `rotate(${degrees}deg)`,
                                '-moz-transform': `rotate(${degrees}deg)`,
                                '-ms-transform': `rotate(${degrees}deg)`,
                                '-o-transform': `rotate(${degrees}deg)`,
                                transform: `rotate(${degrees}deg)`,
                            });
                        }
                    }, 450);
                });
            } else {
                $(this).animate({ rotate: 360 }, {
                    step(now, fx) {
                        $(this).css('-webkit-transform', `rotate(${now}deg)`);
                        $(this).css('-moz-transform', `rotate(${now}deg)`);
                        $(this).css('-ms-transform', `rotate(${now}deg)`);
                        $(this).css('-o-transform', `rotate(${now}deg)`);
                        $(this).css('transform', `rotate(${now}deg)`);
                    },
                }, 300);
                setTimeout(() => {
                    rotate($('.update_icon'), 0);
                    function rotate(object, degrees) {
                        object.css({
                            'font-size': 0,
                            '-webkit-transform': `rotate(${degrees}deg)`,
                            '-moz-transform': `rotate(${degrees}deg)`,
                            '-ms-transform': `rotate(${degrees}deg)`,
                            '-o-transform': `rotate(${degrees}deg)`,
                            transform: `rotate(${degrees}deg)`,
                        });
                    }
                }, 450);
            }
        });

        //      Перетягиване килента
        rm_client_timer.draggable({
            cursor: 'move',
            handle: '.js_draggable_block',
            containment: 'document',
        });
        // console.log(rm_client_timer.draggable());
        popup_time_task.draggable({
            cursor: 'move',
            handle: '.js_popup_head',
            containment: 'document',
        });
        $('.popup_edit_task').draggable({
            cursor: 'move',
            handle: '.head_edit_popup',
            containment: 'document',
        });
        $('.btn_show_client_box').draggable({
            cursor: 'move',
            handle: '.head_edit_popup',
            containment: 'document',
        });
        // $('.js_draggable_block').on('slide mouseenter mousedown', (event) => {
        //     event.stopPropagation();
        // });

        $('.js_btn_log_in').click(() => {
            $('.js_rm_client_timer_login').animate({ opacity: 0 }, 300, function () {
                $(this).removeClass('show');
            });
            task_timer.removeClass('current');
            $('.js_timer_comment').removeClass('block');
            clearAllTime();
            $('.js_list_category').removeClass('block');
            $('.js_list_statuses').removeClass('block');
            $('.js_current_category').removeClass('active');
            $('.js_current_status').removeClass('active');
            task_timer.removeClass('pb0');
            initScrollPane(); // переинициализация скролла
        });
        $('.js_btn_log_out').click(() => {
            if ($('.js_rm_client_timer_login').hasClass('show')) {
                $('.js_rm_client_timer_login').animate({ opacity: 0 }, 300, function () {
                    $(this).removeClass('show');
                });
            }
            // task_timer.removeClass("current");
            $('.js_timer_comment').removeClass('block');
            // clearAllTime();
            $('.js_rm_client_timer_login').addClass('show').animate({ opacity: 1 }, 300);
            $('.js_list_category').removeClass('block');
            $('.js_list_statuses').removeClass('block');
            $('.js_current_category').removeClass('active');
            $('.js_current_status').removeClass('active');
            task_timer.removeClass('pb0');
            // переинициализация скролла
            initScrollPaneLog();
        });

        $('.js_timer_field').bind('keyup change', function () {
            const searchWorld = $(this).val();
            if (searchWorld.length) {
                task_timer.addClass('non_search');
                $(`.js_task_name:contains(${searchWorld})`).parents(task_timer).removeClass('non_search');
                $(`.js_task_number:contains(${searchWorld})`).parents(task_timer).removeClass('non_search');
                $(`.js_task_project:contains(${searchWorld})`).parents(task_timer).removeClass('non_search');

                if ($('.js_task_list .jspPane').length) {
                    task_timer.filter('.non_search').appendTo($('.js_task_list .jspPane'));
                    task_timer.filter('.current').prependTo($('.js_task_list .jspPane'));
                } else {
                    task_timer.filter('.non_search').appendTo($('.js_task_list'));
                    task_timer.filter('.current').prependTo($('.js_task_list'));
                }

                if ($('.js_task_timer.non_search').length == $('.js_task_timer').length) {
                    $('.js_rm_client_timer').addClass('notfound');
                } else {
                    $('.js_rm_client_timer').removeClass('notfound');
                }
                const taskNumber = $('.js_task_list .js_task_timer').length - $('.js_task_list .js_task_timer.none').length;
                const hideTaskNumber = $('.js_task_list .js_task_timer.non_search').length;
                const showSearchTask = taskNumber - hideTaskNumber;
                $('.js_number_tasks_show').text(showSearchTask);
                $('.js_number_tasks_hidden').text(hideTaskNumber);
            } else {
                task_timer.removeClass('non_search');
                $('.js_rm_client_timer').removeClass('notfound');
            }
        });


        if (!$('.js_client_frame').length) { //  for frame rmclinet
            $('.js_btn_hide_client').on('click', () => {
                $('.js_btn_show_client,.js_hover_area').removeAttr('disabled');
                const $wrapper = $('.js--draggable_client');
                const defaultTop = $wrapper.data('defaultTop');
                if (defaultTop) {
                    $wrapper.css('top', defaultTop);
                }
                if (!rm_client_timer.hasClass('hide')) {
                    hide_client();
                    // task_timer.removeClass("current");
                    $('.js_timer_comment_edit').removeClass('show_edit');
                    // $(".time_large").attr("data-time",0);
                    // clearAllTime();
                    // $('.js_list_category').removeClass("block");
                    // $('.js_list_statuses').removeClass("block");
                    // $('.js_current_category').removeClass("active");
                    // $('.js_current_status').removeClass("active");
                    // task_timer.removeClass('pb0');
                    // initScrollPane(); //переинициализация скролла
                }
            });
        }
        $('.js_btn_show_client,.js_hover_area').on('click', (event) => {
            $('.js_btn_show_client,.js_hover_area').attr('disabled', 'disabled');
            const $button = $(event.currentTarget);
            const showHere = $button.data('showHere');
            if (showHere === true) {
                const { top } = $button.offset();
                const $wrapper = $('.js--draggable_client');
                const defaultTop = $wrapper.data('defaultTop') || $wrapper.css('top');
                $wrapper
                    .data('defaultTop', defaultTop)
                    .css('top', `${top - 200}px`);
            }
            $('.js_btn_show_client').parent().addClass('hide');
            setTimeout(() => {
                if (rm_client_timer.hasClass('hide')) {
                    show_client();
                }
            }, 300);
            $('.js_hover_area').addClass('none');
            initScrollPane(); // переинициализация скролла
            //            if($(".js_task_timer.current").length){
            //                var current_timer_block = $(".js_task_timer.current");
            //                var rm_time_small = current_timer_block.find(".time_small");
            //                var rm_time_large = current_timer_block.find(".time_large");
            //
            //                show_popup(rm_time_large, rm_time_small);
            //            }
        });
        $('.js_btn_show_client').on('mouseenter', () => {
            rm_client_timer.addClass('show_mask');
        });
        $('.js_btn_show_client').on('mouseleave', () => {
            rm_client_timer.removeClass('show_mask');
        });

        let timeToHide;
        rm_client_timer.on('mouseleave', () => {
            unlockScroll(document);
            timeToHide = setTimeout(function () {
                if (!$(this).hasClass('hide') && !popup_time_task.hasClass('visible') && !$('.js_client_frame').length) {
                    if (!$('.js_client_frame').length) {
                        hide_client();
                    }
                    $('.popup_edit_task').removeClass('show_edit');

                    // task_timer.removeClass("current");
                    // $(".js_timer_comment").removeClass('block');
                    // $(".time_large").attr("data-time",0);
                    // clearAllTime();
                    // $('.js_list_category').removeClass("block");
                    // $('.js_list_statuses').removeClass("block");
                    // $('.js_current_category').removeClass("active");
                    // $('.js_current_status').removeClass("active");
                    // task_timer.removeClass('pb0');
                }
            }, 90000, () => {
                $('.js_btn_show_client').parent().removeClass('hide');
            });
        });
        rm_client_timer.on('mouseenter', () => {
            clearTimeout(timeToHide);
            lockScroll(document);
        });

        // task filter
        $('.js_select_filter').on('click', function () {
            const parent_box = $(this).parents('.js_select_filter_box');
            if (!parent_box.hasClass('open')) {
                $('.js_select_filter_box').removeClass('open');
                parent_box.addClass('open');
            } else {
                parent_box.removeClass('open');
            }
        });


        $(() => {
            let modeKey = 'none';
            $(document).keydown((event) => {
                if (event.keyCode == 17) {
                    modeKey = 'ctrl';
                }
            }).keyup((event) => {
                if (event.keyCode == 17) {
                    modeKey = 'not_ctrl';
                }
            });

            $('.js_select_field_list.js_multi_filter li').on('click', function () {
                const list_item_text = $(this).text();
                if (modeKey == 'ctrl') {
                    $(this).toggleClass('multifilter');
                    $(this).parents('.js_select_filter_box').addClass('open');
                    const numberSelect = $(this).parents('.js_select_field_list').find('li.multifilter').length;
                    if (numberSelect > 1 && $(this).parents('.js_select_field_list').find('li').length != 2) {
                        $(this).parents('.js_select_filter_box').find('.js_select_filter').text(`Selected ${numberSelect} items`);
                    } else if (numberSelect == 1) {
                        const activeFilter = $(this).parents('.js_select_field_list').find('li.multifilter').text();
                        $(this).parents('.js_select_filter_box').find('.js_select_filter').text(activeFilter);
                    } else if ($(this).parents('.js_select_field_list').find('li').length == 2) {
                        $(this).parents('.js_select_filter_box').find('.js_select_filter').html('Redmine user');
                    } else if (numberSelect == 0) {
                        $(this).parents('.js_select_filter_box').find('.js_select_filter').text(list_item_text);
                    }
                    if ($(this).parents('.js_select_filter_box').find('.js_select_field_list li:eq(0)').hasClass('multifilter')) {
                        $(this).parents('.js_select_filter_box').find('.js_select_field_list li:eq(0)').removeClass('multifilter');
                    }
                    if ($(this).text() == '*Any*') {
                        $(this).parents('.js_select_filter_box').find('.js_select_field_list li').removeClass('multifilter');
                        $(this).addClass('multifilter');
                        $(this).parents('.js_select_filter_box').find('.js_select_filter').text(list_item_text);
                    }
                } else {
                    $(this).parents('.js_select_filter_box').find('.js_select_field_list li').removeClass('multifilter');
                    $(this).addClass('multifilter');
                }
            });
        });


        $('.js_select_field_list li').on('click', function () {
            const list_item_text = $(this).text();
            if ($('.js_timer_comment_edit').hasClass('show_edit')) {
                $(this).siblings().removeClass('current');
                $(this).parents('.js_task_timer').find('.js_list_statuses li').removeClass('current');
                $(this).addClass('current');
            }
            // if (!($('.js_multi_filter li').hasClass("multifilter"))){
            $(this).parents('.js_select_filter_box').find('.js_select_filter').text(list_item_text);
            // }
            $('.js_select_filter_box').removeClass('open');
        });
        $('.popup_status_list.js_select_field_list li').on('click', function () {
            const index_task = $(this).parents('.js_timer_comment_edit').attr('data-editId');
            const text = $(this).html();
            $(`[data-taskId=${index_task}]`).find('.js_current_status').html(text);
            $(`[data-editId=${index_task}]`).find('.js_popup_current_status').html(text);
            const number = $(this).index();
            const status_name = $(`[data-taskId=${index_task}]`).find('.js_list_statuses li')[number];
            $(`[data-taskId=${index_task}]`).find('.js_list_statuses li').removeClass('current');
            $(status_name).addClass('current');
        });
        $('body').on('click', (event) => {
            if ($(event.target).parents('.js_select_filter_box').length == 0) {
                $('.js_select_filter_box').removeClass('open');
            }
        });
        $('.js_priority_list li').on('click', function () {
            const index_task = $(this).parents('.js_timer_comment_edit').attr('data-editId');
            const text = $(this).html();
            const prior_text = $(this).parents('.js_timer_comment_edit').find('.js_priority_text').text();
            $(this).parents('.js_timer_comment_edit').find('.js_text_in_head').text(prior_text);
            $(`[data-taskId=${index_task}]`).find('.js_task_priority').html(text);
        });

        $('.js_number_tasks_show').text((task_timer.length));

        $('.js_btn_filter_show').on('click', () => {
            let project_filt; let track_filt; let stat_filt; let prior_filt; let vers_filt; let i = 0; let j = 0;
            task_timer.removeClass('non_search');
            task_timer.each(function (elem) {
                const e_name = $(this).find('.js_task_project').text();
                const e_track = $(this).find('.js_current_category').text();
                const e_stat = $(this).find('.js_current_status').text();
                const e_prior = $(this).find('.js_task_priority').text();
                const e_vers = $(this).data('version');
                // console.log(e_name, e_track, e_stat, e_prior, e_vers)
                project_filt = false;
                track_filt = false;
                stat_filt = false;
                prior_filt = false;
                vers_filt = false;

                for (var kk = 0; kk < $('.js_select_list_project_multi li.multifilter').length; kk++) {
                    if ($(`.js_select_list_project_multi li:eq(${kk}).multifilter`).text() == e_name) {
                        project_filt = true;
                    }
                }
                for (var kk = 0; kk < $('.js_select_list_tracker_multi li.multifilter').length; kk++) {
                    if ($(`.js_select_list_tracker_multi li:eq(${kk}).multifilter`).text() == e_track) {
                        track_filt = true;
                    }
                }
                for (var kk = 0; kk < $('.js_select_list_status_multi li.multifilter').length; kk++) {
                    if ($(`.js_select_list_status_multi li:eq(${kk}).multifilter`).text() == e_stat) {
                        stat_filt = true;
                    }
                }
                for (var kk = 0; kk < $('.js_select_list_priority_multi li.multifilter').length; kk++) {
                    if ($(`.js_select_list_priority_multi li:eq(${kk}).multifilter`).text() == e_prior) {
                        prior_filt = true;
                    }
                }
                for (var kk = 0; kk < $('.js_select_list_version_multi li.multifilter').length; kk++) {
                    if ($(`.js_select_list_version_multi li:eq(${kk}).multifilter`).text() == e_vers) {
                        vers_filt = true;
                    }
                }


                if ($('.js_select_list_project').text() == e_name || $('.js_select_list_project').text() == '*Any*') {
                    project_filt = true;
                }

                if ($('.js_select_list_tracker').text() == e_track || $('.js_select_list_tracker').text() == '*Any*') {
                    track_filt = true;
                }
                if ($('.js_select_list_status').text() == e_stat || $('.js_select_list_status').text() == '*Any*') {
                    stat_filt = true;
                }
                if ($('.js_select_list_priority').text() == e_prior || $('.js_select_list_priority').text() == '*Any*') {
                    prior_filt = true;
                }
                if ($('.js_select_list_version').text() == e_vers || $('.js_select_list_version').text() == '*Any*') {
                    vers_filt = true;
                }

                if (project_filt && track_filt && stat_filt && prior_filt && vers_filt) {
                    i += 1;
                } else {
                    $(this).addClass('non_search');
                    j += 1;
                }
            });
            if (j > 0) {
                $('.js_btn_basket').removeClass('none');
            }
            if (i == 0) {
                $('.js_no_filter_result').removeClass('none');
            } else {
                $('.js_no_filter_result').addClass('none');
            }

            $('.js_number_tasks_show').text(i);
            $('.js_number_tasks_hidden').text(j);


            if ($('#cc9910').prop('checked') == true) {
                $('.js_number_tasks_show').text(($('.js_task_list .js_task_timer').length - $('.js_task_list .js_task_timer.none').length) - ($('.js_task_list .js_task_timer.non_search').length - $('.js_task_list .js_task_timer.non_search.none').length));
                $('.js_number_tasks_hidden').text($('.js_task_list .js_task_timer.non_search').length - $('.js_task_list .js_task_timer.non_search.none').length);
            }

            task_timer.filter('.non_search').appendTo($('.js_task_list .jspPane'));
            task_timer.filter('.current').prependTo($('.js_task_list .jspPane'));
            filter_field.removeClass('block').addClass('none');
            $('.js_task_list').css({ height: $('.js_task_list').height() + 344 });
            initScrollPane(); // переинициализация скролла
        });
        $('.js_btn_filter_reset').on('click', () => {
            $('.js_number_tasks_show').text((task_timer.length));
            $('.js_number_tasks_hidden').text('0');
            $('.js_multi_filter_text').text('*Any*');
            $('.js_btn_basket').addClass('none');
            $('.js_select_field_list.js_multi_filter li').removeClass('multifilter');
            $('.js_no_filter_result').addClass('none');
            task_timer.removeClass('non_search');
            if ($('#cc9910').prop('checked') == true) {
                $('.js_number_tasks_show').text('2');
            }
        });

        // task filter end

        $('.js_list_category li').on('click', function () {
            const text = $(this).html();
            $(this).parents('.js_list_category').find('li').removeClass('current');
            $(this).addClass('current');
            $(this).parents('.js_task_timer').find('.js_current_category').html(text)
                .removeClass('active');
            $(this).parents('.js_task_timer').find('.js_current_status').removeClass('active');
            $(this).parents('.js_task_timer').find('.js_list_category').removeClass('block');
            $(this).parents('.js_task_timer').removeClass('pb0');
            initScrollPane(); // переинициализация скролла
        });
        $('.js_list_statuses li').on('click', function () {
            const text = $(this).html();
            $(this).parents('.js_list_statuses').find('li').removeClass('current');
            $(this).parents('.js_task_timer').find('.popup_status_list li').removeClass('current');
            $(this).addClass('current');
            $(this).parents('.js_task_timer').find('.js_current_status').html(text)
                .removeClass('active');
            $(this).parents('.js_task_timer').find('.js_current_category').removeClass('active');
            $(this).parents('.js_task_timer').find('.js_list_statuses').removeClass('block');
            $(this).parents('.js_task_timer').removeClass('pb0');
            $(this).parents('.js_task_timer').find('.js_current_status').html(text);
            $(this).parents('.js_task_timer').find('.js_popup_current_status').html(text);
            const number = $(this).index();
            const status_name = $(this).parents('.js_task_timer').find('.popup_status_list li')[number];
            $(status_name).addClass('current');
            initScrollPane(); // переинициализация скролла
        });
        $('.js_current_category').on('click', function () {
            if ($(this).parents('.js_task_timer').find('.js_list_category').hasClass('block')) {
                $(this).parents('.js_task_timer').find('.js_list_category').removeClass('block');
                $(this).removeClass('active');
                $(this).parents('.js_task_timer').find('.js_current_status').removeClass('active');
                task_timer.removeClass('pb0');
            } else {
                $(this).parents('.js_task_timer').find('.js_list_category').addClass('block');
                $(this).addClass('active');
                $(this).parents('.js_task_timer').find('.js_current_status').addClass('active');
                $(this).parents('.js_task_timer').addClass('pb0');
            }
            if ($(this).parents('.js_task_timer').find('.js_list_statuses').hasClass('block')) {
                $(this).parents('.js_task_timer').find('.js_list_statuses').removeClass('block');
            }
            initScrollPane(); // переинициализация скролла
        });
        $('.js_current_status').on('click', function () {
            if ($(this).parents('.js_task_timer').find('.js_list_statuses').hasClass('block')) {
                $(this).parents('.js_task_timer').find('.js_list_statuses').removeClass('block');
                $(this).removeClass('active');
                $(this).parents('.js_task_timer').find('.js_current_category').removeClass('active');
                task_timer.removeClass('pb0');
            } else {
                $(this).parents('.js_task_timer').find('.js_list_statuses').addClass('block');
                $(this).addClass('active');
                $(this).parents('.js_task_timer').find('.js_current_category').addClass('active');
                $(this).parents('.js_task_timer').addClass('pb0');
            }
            if ($(this).parents('.js_task_timer').find('.js_list_category').hasClass('block')) {
                $(this).parents('.js_task_timer').find('.js_list_category').removeClass('block');
            }
            initScrollPane(); // переинициализация скролла
        });


        function lockScroll(elem) {
            if (elem.addEventListener) {
                elem.addEventListener('DOMMouseScroll', elem.onmousewheel = function (e) {
                    e.preventDefault();
                }, false);
            } else {
                elem.onmousewheel = function (e) {
                    window.event.returnValue = false;
                };
            }
        }
        function unlockScroll(elem) {
            if (elem.removeEventListener) {
                elem.removeEventListener('DOMMouseScroll', elem.onmousewheel, false);
                elem.onmousewheel = null;
            } else {
                elem.onmousewheel = null;
            }
        }

        function nowSeverTime() {
            const SeverTime = new Date();
            const SeverTimeH = SeverTime.getHours();
            const SeverTimeM = SeverTime.getMinutes();
            if (SeverTimeH > 9) {
                $('.js_current_time .hours').html(SeverTimeH);
            } else {
                $('.js_current_time .hours').html(`0${SeverTimeH}`);
            }
            if (SeverTimeM > 9) {
                $('.js_current_time .minutes').html(SeverTimeM);
            } else {
                $('.js_current_time .minutes').html(`0${SeverTimeM}`);
            }
        }
        nowSeverTime();
        setInterval(() => {
            nowSeverTime();
        }, 1000);

        function clearAllTime() {
            clearInterval(timeInt);
            clearTimeout(popupTime);
            clearTimeout(rmTimeOut);
            clearTimeout(rmTimeOut2);
            clearInterval(timeInt2);
        }
        function secondsToHms(d) {
            d = Number(d);
            const h = Math.floor(d / 3600);
            const m = Math.floor(d % 3600 / 60);
            const s = Math.floor(d % 3600 % 60);
            return (
                (h > 0 ? `${(h < 10 ? '0' : '') + h}:` : '')
                + (m > 0 ? `${(h >= 0 && m < 10 ? '0' : '') + m}:` : '00:')
                + (s < 10 ? '0' : '') + s
            );
        }
        function hide_client() {
            rm_client_timer.animate({
                right: 1,
                top: 165,
                left: 0,
            }, 500, 'easeOutCubic', () => {
                rm_client_timer.removeClass('show').addClass('hide').animate({
                    right: 1,
                    top: 0,
                    left: 0,
                }, 400, () => {
                    $('.js_btn_show_client').parent().removeClass('hide');
                    $('.js_hover_area').removeClass('none');
                });
            });
            rm_client_timer.removeClass('show').addClass('hide');
            $('.js_btn_show_client').parent().removeClass('hide');
            $('.js_hover_area').removeClass('none');
            $('.js--draggable_client_container').css('overflow', 'hidden');
            $('#date_start1, #date_end1, #date_start2, #date_end2, #date_start3, #date_end3, #date_start4, #date_end4, #date_start5, #date_end5').datepicker('hide');
            $('.js_timer_comment_edit').removeClass('show_edit');
            $('.js--draggable_client').removeClass('overflow');
            $('.js--draggable_client_container').removeClass('active');


            if ($('.js_rm_client_timer_login').hasClass('show')) {
                $('.js_rm_client_timer_login').animate({ opacity: 0 }, 300, () => {
                    $('.js_rm_client_timer_login').removeClass('show');
                });
            }
            if (!$('.js_btn_play_t:first').parents('.task').hasClass('current')) {
                $('.js_btn_play_t:first').click();
            }


            if ($('.rm_timer_filter').hasClass('block')) {
                filterclick();
            }

            if ($('.rm_timer_search').hasClass('block')) {
                searchclick();
            }
            $('.jspPane').css({ top: '0px' });

            $('.datepiker_input').blur();

            // $('.timer_log_field input').blur();
        }
        function show_client() {
            // rm_client_timer.animate({
            //    left: -36,
            //    top: -103
            // },500,"easeOutCubic",function(){
            //    rm_client_timer.removeClass("hide").addClass("show").animate({
            //        left: 61,
            //        top: -3
            //    },400)
            // });
            rm_client_timer.removeClass('hide').addClass('show');
            $('.js--draggable_client_container').css('overflow', 'visible');
            $('.js--draggable_client').addClass('overflow');
            // rm_client_timer.animate({opacity: "1"}, 1000);
        }
        function playTimer(time_l, time_s) {
            time_l.attr('data-time', 0);
            time_l.html(secondsToHms(0));
            //          время задачи
            rmTimeOut = setTimeout(() => {
                time_l.html(secondsToHms(+time_l.attr('data-time') + 1));
                time_s.html(secondsToHms(+time_s.attr('data-time') + 1));
            }, 1000);
            timeInt = setInterval(() => {
                time_l.attr('data-time', +time_l.attr('data-time') + 1);
                time_s.attr('data-time', +time_s.attr('data-time') + 1);
                time_l.html(secondsToHms(+time_l.attr('data-time')));
                time_s.html(secondsToHms(+time_s.attr('data-time')));
            }, 1000);
            //          время задачи. конец
        }
        function popupTimer(time_l2, time_s2, popup_time_val) {
            time_l2.attr('data-time', popup_time_val + parseInt(time_l2.attr('data-time'), 10));
            time_s2.attr('data-time', popup_time_val + parseInt(time_s2.attr('data-time'), 10));

            rmTimeOut2 = setTimeout(() => {
                time_l2.html(secondsToHms(+time_l2.attr('data-time') + 1));
                time_s2.html(secondsToHms(+time_s2.attr('data-time') + 1));
            }, 1000);
            timeInt2 = setInterval(() => {
                time_l2.attr('data-time', +time_l2.attr('data-time') + 1);
                time_s2.attr('data-time', +time_s2.attr('data-time') + 1);
                time_l2.html(secondsToHms(+time_l2.attr('data-time')));
                time_s2.html(secondsToHms(+time_s2.attr('data-time')));
            }, 1000);
        }
        //      показывать попап с таймером, добавить или не добавлять время
        // function show_popup(time_large, time_small) {
        //     if (!$('.js_client_frame').length) {
        //         popupTime = setTimeout(() => {
        //             if (!$('#rm_client_timer.hide').length && task_timer.hasClass('current')) {
        //                 clearAllTime();
        //                 popup_time.attr('data-time', 0);
        //                 popup_time.html(secondsToHms(0));
        //                 popup_time_task.addClass('visible');
        //                 $('.js_timer_comment').removeClass('block');
        //                 popupTimeOutCount = setTimeout(() => {
        //                     popup_time.html(secondsToHms(+popup_time.attr('data-time') + 1));
        //                 }, 1000);
        //                 popupTimeCount = setInterval(() => {
        //                     popup_time.attr('data-time', +popup_time.attr('data-time') + 1);
        //                     popup_time.html(secondsToHms(+popup_time.attr('data-time')));
        //                 }, 1000);
        //                 rm_client_timer.addClass('popup_open');
        //                 $('#date_start1, #date_end1, #date_start2, #date_end2, #date_start3, #date_end3, #date_start4, #date_end4, #date_start5, #date_end5').datepicker('hide');
        //
        //                 $('.datepiker_input').prop('disabled', true);
        //
        //                 $('.js_timer_comment_edit').removeClass('show_edit');
        //
        //                 $('.js_add_time_totask').off('click').on('click', () => {
        //                     const popup_time_val = parseInt((popup_time.attr('data-time')), 10);
        //                     popup_time_task.removeClass('visible');
        //                     clearInterval(popupTimeCount);
        //                     clearTimeout(popupTimeOutCount);
        //                     clearAllTime();
        //                     rm_client_timer.removeClass('popup_open');
        //                     popupTimer(time_large, time_small, popup_time_val);
        //                     popup_time.attr('data-time', 0);
        //                 });
        //                 $('.js_cansel_time_totask').add('.js_btn_close_popup').off('click').on('click', () => {
        //                     popup_time_task.removeClass('visible');
        //                     clearInterval(popupTimeCount);
        //                     clearTimeout(popupTimeOutCount);
        //                     clearAllTime();
        //                     rm_client_timer.removeClass('popup_open');
        //                     popupTimer(time_large, time_small, 0);
        //                     popup_time.attr('data-time', 0);
        //                 });
        //             } else {
        //                 clearTimeout(popupTime);
        //             }
        //         }, 60000);
        //     }
        // }
    }
    //  checkbox for rm log panel
    $('.js_checkbox_box input').each(function () {
        const checked_el = $(this).prop('checked');
        if (checked_el) {
            $(this).parents('.js_checkbox_box').addClass('active');
        } else {
            $(this).parents('.js_checkbox_box').removeClass('active');
        }
    });
    $('.js_checkbox_box input').on('change', function () {
        const checked_el = $(this).prop('checked');
        if (checked_el) {
            $(this).parents('.js_checkbox_box').addClass('active');
        } else {
            $(this).parents('.js_checkbox_box').removeClass('active');
        }
    });
    $('#font_change').on('change', function () {
        const checked_el = $(this).prop('checked');
        if (checked_el) {
            $('#rm_client_timer').addClass('arial_font');
        } else {
            $('#rm_client_timer').removeClass('arial_font');
        }
    });
    //  for frame rmclinet
    if ($('.js_client_frame').length) {
        rm_client_timer.removeClass('hide').addClass('show');
        $('.js_hover_area').remove();
        $('.js_client_frame .btn_show_client_box').remove();
    }

    $(task_timer[0]).find('.js_btn_play_t').trigger('click'); // запускаем задачу


    // убираем ховер когда открыто окно редактирования задачи
    $('.popup_edit_task').mouseover(function () {
        $(this).parents('.task').removeClass('hover_task');
    });

    $('.popup_edit_task').mouseout(function () {
        $(this).parents('.task').addClass('hover_task');
    });

    $('.popup_edit_task').hover(
        () => {
            $('.js_btn_open_comment').css({ opacity: '0' });
            $('.js_btn_play_t').css({ opacity: '0' });
            $('.js_btn_stop_t').css({ opacity: '0' });
        },
        () => {
            $('.js_btn_open_comment').css({ opacity: '' });
            $('.js_btn_play_t').css({ opacity: '' });
            $('.js_btn_stop_t').css({ opacity: '' });
        },
    );
});
