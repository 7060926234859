class Cookie {
    constructor($button, $block) {
        this.$button = $button;
        this.$block = $block;
    }

    init() {
        if (this.$button && this.$button.length && this.$block && this.$block.length) {
            this.checkCookie();
            this.$button.click(() => {
                const date = new Date();
                date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
                document.cookie = `showcookietext=${true}; path=/; expires=${date.toGMTString()}`;
                this.$block.fadeOut(200);
            });
        }
    }

    static getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts
                .pop()
                .split(';')
                .shift();
        }
        return false;
    }

    checkCookie() {
        const textCookie = Cookie.getCookie('showcookietext');
        if (textCookie === 'true') {
            this.$block.hide(0);
        } else {
            this.$block.show(0);
        }
    }
}

export default Cookie;
