$(() => {
    const settings = {
        arrows: false,
        dots: false,
        fade: true,
        infinite: true,
        swipe: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 961,
                settings: {
                    autoplay: true,
                    waitForAnimate: false,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                    swipe: true,
                    dots: true,
                },
            },
        ],
    };

    $('.js--hero_slider').slick(settings);
    $('.js--hero_slider').on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        const wrapper = $('.slick-current').find('.js--client-wrapper');
        const clientInsideNextSlide = $(`.slick-slide[data-slick-index='${nextSlide}']`).find('.js--client-wrapper');
        if (wrapper.length > 0) {
            $('.js--draggable_client').removeClass('show_demo');
        }
        if (clientInsideNextSlide.length > 0) {
            $('.js--draggable_client').addClass('show_demo');
        }

    });
    const mob = window.matchMedia('(max-width: 768px)');
    function democlientSwitcher(mob) {
        if (mob.matches) {
            $('.demo').appendTo('.js--client-wrapper');
        }
    }
    democlientSwitcher(mob);
    $('.js--hero_slider').on('touchstart', () => {
        $('.js--hero_slider').slick('slickPlay');
    });
});
