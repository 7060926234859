const faq = {

    init() {
        faq.addHandlers();
        faq.setCurrentLink();
        faq.initStikySidebar();
        $(window).resize(() => {
            faq.initStikySidebar();
        });
    },
    setCurrentLink() {
        const hash = window.location.hash.replace('#', '');
        let element = $(`.faq_menu__link[data-slug="${hash}"]`);
        if (element.length > 0) { faq.filtrate(element); }
        element = $(`.faq_accordion__title[data-question="${hash}"]`);
        if (element.length > 0) { faq.openAnswer(element); }
    },
    addHandlers() {
        $('.js--faq_menu').click((e) => {
            const element = $(e.target);
            faq.filtrate(element);
        });
        $('.js--faq_accordion__title').click((e) => {
            const element = $(e.target);
            faq.openAnswer(element);
        });
    },
    filtrate(element) {
        $('.faq_menu__link').removeClass('faq_menu__link--active');
        element.addClass('faq_menu__link--active');
        const currentDataId = element.data('id');
        if (currentDataId === '#all') {
            $('.faq_accordion__row').each(function () {
                $(this).slideDown(500);
            });
        } else {
            $('.faq_accordion__row').each(function () {
                if ($(this).data('id').includes(String(currentDataId))) {
                    $(this).slideDown(500);
                } else {
                    $(this).slideUp(500);
                }
            });
        }
    },
    openAnswer(element) {
        $('.faq_accordion__row').find('.faq_accordion__item').slideUp(500);
        $('.faq_accordion__title')
            .removeClass('faq_accordion__title--active');
        const currentRow = element.closest('.faq_accordion__row');
        const answer = currentRow.find('.faq_accordion__item');
        if (answer.css('display') === 'none') {
            answer.slideDown(500, () => {
                if ((currentRow.offset().top - $(window).scrollTop())
                    < $('.header').outerHeight()
                    || currentRow.offset().top + currentRow.outerHeight()
                    > $(window).scrollTop() + $(window).height()) {
                    $('html, body').animate({
                        scrollTop: currentRow.offset().top
                            - $('.header').outerHeight() - 20,
                    }, 800);
                }
            });
            $('.faq_accordion__title')
                .removeClass('faq_accordion__title--active');
            currentRow.find('.faq_accordion__title')
                .addClass('faq_accordion__title--active');
        }
    },
    initStikySidebar() {
        if (faq.stikySidebar) { faq.stikySidebar.destroy(); }
        if (window.matchMedia('(min-width: 768px)').matches) {
            faq.stikySidebar = new StickySidebar('.sidebar', faq.settingsStikySidebar);
        }
    },
    settingsStikySidebar: {
        topSpacing: $('header').height(),
        bottomSpacing: 20,
        containerSelector: '.faq',
        innerWrapperSelector: '.sidebar__inner',
    },
};

export default faq;
