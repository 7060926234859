import signIn from '../blocks/modals/sign_in/sign_in';
import emailConfirm from '../blocks/modals/email_confirm/email_confirm';
import signUp from '../blocks/modals/sign_up/sign_up';
import recovery from '../blocks/modals/recovery/recovery';
import feedBack from '../blocks/modals/feedback/feedback';
import profile from '../blocks/profile_content/profile';
import subscribe from '../blocks/subscribe/subscribe';

const validate = {
    init() {
        // validate.initCustomRules();
        jQuery.validator.addMethod('whiteSpaceOnly', function (value, element) {
            return this.optional(element) || !/^\s+$/.test(value);
        }, 'Only whitespace');
        jQuery.validator.addMethod(
            'onlyEnglish',
            function onlyEnglish(value, element) {
                // eslint-disable-next-line no-useless-escape
                return this.optional(element) || /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$/g.test(value);
            },
            'only english characters',
        );

        $('.js--validate_form').each((i, form) => {
            const nameForm = $(form).attr('id');
            const nameFormName = `forms_${nameForm}_name`;
            const nameFormSearch = `forms_${nameForm}_search`;
            const nameFormEmail = `forms_${nameForm}_email`;
            const $nameFormEmail = $(`#${nameFormEmail}`);
            const nameFormMessage = `forms_${nameForm}_message`;
            const password = `forms_${nameForm}_password`;
            const oldPassword = `forms_${nameForm}_oldPassword`;
            const passwordConfirmation = `forms_${nameForm}_pass_conf`;
            const rulesDefault = {
                required: true,
                minlength: 3,
                whiteSpaceOnly: true,
            };
            $(form).validate({
                rules: {
                    [nameFormName]: rulesDefault,
                    [nameFormSearch]: { minlength: 3 },
                    [nameFormEmail]: Object.assign({
                        email: true,
                        onlyEnglish: true,
                    }, rulesDefault),
                    [nameFormMessage]: rulesDefault,
                    [password]: rulesDefault,
                    [oldPassword]: rulesDefault,
                    [passwordConfirmation]: Object.assign({
                        equalTo: `#${password}`,
                    }, rulesDefault),
                },
                messages: {
                    [nameFormName]: {
                        required: $(`#${nameFormName}`).data('error_required'),
                        whiteSpaceOnly: $(`#${nameFormName}`).data('error_required'),
                        minlength: $(`#${nameFormName}`).data('error_length'),
                    },
                    [nameFormSearch]: {
                        minlength: $(`#${nameFormSearch}`).data('error_length'),
                    },
                    [nameFormEmail]: {
                        required: $nameFormEmail.data('error_required'),
                        whiteSpaceOnly: $nameFormEmail.data('error_required'),
                        email: $nameFormEmail.data('error_email'),
                        minlength: $nameFormEmail.data('error_length'),
                        onlyEnglish: $nameFormEmail.data('error_email'),
                    },
                    [nameFormMessage]: {
                        required: $(`#${nameFormMessage}`)
                            .data('error_required'),
                        whiteSpaceOnly: $(`#${nameFormMessage}`)
                            .data('error_required'),
                        minlength: $(`#${nameFormMessage}`)
                            .data('error_length'),
                    },
                    [oldPassword]: {
                        required: $(`#${password}`).data('error_required'),
                        whiteSpaceOnly: $(`#${password}`).data('error_required'),
                        minlength: $(`#${password}`).data('error_length'),
                    },
                    [password]: {
                        required: $(`#${password}`).data('error_required'),
                        whiteSpaceOnly: $(`#${password}`).data('error_required'),
                        minlength: $(`#${password}`).data('error_length'),
                    },
                    [passwordConfirmation]: {
                        required: $(`#${passwordConfirmation}`).data('error_required'),
                        whiteSpaceOnly: $(`#${passwordConfirmation}`).data('error_required'),
                        minlength: $(`#${passwordConfirmation}`).data('error_length'),
                        equalTo: $(`#${passwordConfirmation}`).data('error_equalto'),
                    },
                },
                errorElement: 'span',
                errorPlacement(error, element) {
                    const selector = `.form_${$(element)
                        .prop('nodeName')
                        .toLowerCase()}`;
                    error.appendTo(element.siblings(`${selector}__label`)
                        .find(`${selector}__error`));
                },
                // errorContainer: '.form_input__error',
                highlight(element) {
                    const selector = `.form_${$(element)
                        .prop('nodeName')
                        .toLowerCase()}`;
                    $(element.closest(selector)).addClass('wrapper_error');
                },
                unhighlight(element) {
                    const selector = `.form_${$(element)
                        .prop('nodeName')
                        .toLowerCase()}`;
                    const elementWrapper = $(element.closest(selector));
                    elementWrapper.removeClass('wrapper_error');
                    elementWrapper.find(`${selector}__error`).empty();
                },
                submitHandler(form) {
                    // FB.AppEvents.logEvent("SubmitForm");
                    grecaptcha.ready(() => {
                        grecaptcha.execute('6LcWsAAVAAAAAJYdSxYcw99WcFq_N3hQgr9V-D5X', { action: 'submit' }).then((token) => {
                            document.getElementById('gRecaptchaResponse').value = token;
                            switch ($(form).attr('id')) {
                                case 'sign_in':
                                    signIn.send();
                                    break;
                                case 'email_confirm':
                                    emailConfirm.send();
                                    break;
                                case 'sign_up':
                                    signUp.submit();
                                    break;
                                case 'subscribe':
                                    subscribe.submit($(form));
                                    break;
                                case 'recovery':
                                    recovery.submit();
                                    break;
                                case 'new_password':
                                    recovery.setNewPassword();
                                    break;
                                case 'feedback':
                                    feedBack.submit($(form));
                                    break;
                                case 'contact_us':
                                    feedBack.submit($(form));
                                    break;
                                case 'user_data':
                                    profile.edit('#user_data');
                                    break;
                                case 'user_access':
                                    profile.edit('#user_access');
                                    break;
                                case 'bind_email':
                                    profile.edit('#bind_email');
                                    break;
                                default:
                                    break;
                            }
                        });
                    });
                },
            });
        });
    },
};

export default validate;
