$(document).ready(() => {
    $('.js--article_content').find('h2').each(function () {
        const header = $(this)[0].innerText;
        $(this).attr('data-name', header);
        $('.js--article_headers').append(`<li class="post__nav_item" data-scroll="${header}">${header}</li>`);
    });
    $('.js--article_headers li').on('click', function () {
        const navItem = $(this).data('scroll');
        $('html, body').animate({
            scrollTop: $('.js--article_content').find(`h2[data-name='${navItem}']`).offset().top - 90,
        }, 500);
    });
    const headersCount = $('.js--article_content').find('h2');
    const copyPostForm = $('.js--post_help').clone();
    $(copyPostForm).insertBefore(headersCount[1]);
    if (headersCount.length >= 8) {
        $(copyPostForm).clone().insertBefore(headersCount[5]);
    }
    if (headersCount.length > 16) {
        $(copyPostForm).clone().insertBefore(headersCount[9]);
    }
})
