
import showModal from '../../../containers/modal/modal';

const signIn = {
    send() {
        const form = $('#sign_in');
        form.find('button:submit').prop('disabled', true);
        this.clearErrors();
        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            data: form.serialize(),
            success(rsp) {
                form.find('button:submit').prop('disabled', false);
                if (rsp.status === 'ok') {
                    window.location.href = rsp.redirect_url;
                } else if (rsp.code === 401) {
                    window.userHash = rsp.hash;
                    showModal($('.js--modal_email_confirm'));
                } else {
                    const inputBlock = $('#forms_sign_in_email').closest('.form_input');
                    inputBlock.addClass('wrapper_error');
                    inputBlock.find('.form_input__error').css('display', 'block');
                    $('<span>', { class: 'server_error', text: rsp.error }).appendTo(inputBlock.find('.form_input__error'));
                    inputBlock.find('input').one('keyup', () => {
                        inputBlock.removeClass('wrapper_error');
                        inputBlock.find('.server_error').remove();
                    });
                }
                const params = {};
                params[FB.AppEvents.ParameterNames.REGISTRATION_METHOD] = 'sign_in';
                FB.AppEvents.logEvent(FB.AppEvents.EventNames.COMPLETED_REGISTRATION, null, params);
            },
            error() {
                form.find('button:submit').prop('disabled', false);
                showModal($('.js--modal_error'));
            },
        });
    },
    clearErrors() {
        const inputBlock = $('#forms_sign_in_email').closest('.form_input');
        inputBlock.removeClass('wrapper_error');
        inputBlock.find('.server_error').remove();
    },
    initEvent() {
        $('.js--sign_in').click(() => {
            $('.modal').removeClass('active');
            $('.js--modal_sign_in').addClass('active');
        });
    },
};
export default signIn;
