// import { modalEvents } from '../../../containers/modal/modal';

const signUp = {
    showModal() {
        $('.modal').removeClass('active');
        $('.js--modal_sign_up').addClass('active');
        // modalEvents.pageScrollDisable();
        if ($(this).closest('header').length > 0) {
            $('.js--menu').trigger('click');
        }
    },
    initEvent() {
        $('.js--sign_up').click((e) => {
            e.preventDefault();
            signUp.showModal();
        });
    },
    submit() {
        const $form = $('#sign_up');
        const data = signUp.getDataFrom($form);
        $form.find('button:submit').prop('disabled', true);
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            contentType: 'application/json',
            data: JSON.stringify(data),
            success(rsp) {
                if (rsp.status === 'ok') {
                    $('.js--gmail_button').removeClass('show');
                    if (data.email.indexOf('@gmail.com') + 1) {
                        $('.js--gmail_button').addClass('show');
                    }
                    $form.find('button:submit').prop('disabled', false);
                    $('.modal.active').removeClass('active');
                    $('.js--modal_thanks').addClass('active');
                    signUp.clearForm();

                    const params = {};
                    params[FB.AppEvents.ParameterNames.REGISTRATION_METHOD] = 'sign_up';
                    FB.AppEvents.logEvent(FB.AppEvents.EventNames.COMPLETED_REGISTRATION, null, params);
                } else {
                    signUp.showErrorsInForm($form, rsp);
                }
            },
            error() {
                $form.find('button:submit').prop('disabled', false);
                $('.modal').removeClass('active');
                $('.js--modal_error_offline').addClass('active');
            },
        });
    },
    getDataFrom($form) {
        const inputsAll = $form.find('input');
        const findInputWith = function (substring) {
            return inputsAll.filter(function () {
                return this.id.includes(substring);
            }, substring);
        };
        const data = {
            full_name: findInputWith('name').val().trim(),
            email: findInputWith('email').val().trim(),
            password: findInputWith('pass').val(),
            subscribe: findInputWith('subsc').prop('checked'),
            gRecaptchaResponse: $('#gRecaptchaResponse').val(),
        };
        return data;
    },
    clearForm() {
        $('#sign_up').find('input, textarea').val('');
    },
    closeForm() {
        signUp.clearForm();
        $('.modal').removeClass('active');
    },
    showErrorsInForm($form, rsp) {
        $form.find('button:submit').prop('disabled', false);
        const { errors } = rsp;
        errors.forEach((err) => {
            $form.find('input').each(function () {
                if ($(this)[0].id.includes(err.property_path)) {
                    const $input = $(this).closest('.form_input');
                    $input.addClass('wrapper_error');
                    $input.find('.form_input__error').append((`<span class="error">${err.message}</span>`))
                        .show();
                }
            }, err);
            // пришлось вынести отдельно. из-за ID. Не может быть 2х ID
            if (err.property_path === 'gRecaptchaResponse') {
                $form.find('.recaptcha__error').addClass('show');
                $form.find('.recaptcha__error_text').text(err.message);
            }
        });
    },
};

export default signUp;
