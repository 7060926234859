import showModal from '../../../containers/modal/modal';

const emailConfirm = {
    send() {
        const form = $('#email_confirm');
        form.find('button:submit').prop('disabled', true);
        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            contentType: 'application/json',
            data: JSON.stringify({ hash: window.userHash }),
            success(rsp) {
                form.find('button:submit').prop('disabled', false);
                if (rsp.status === 'ok') {
                    showModal($('.js--modal_email_confirm_success'));
                }
            },
            error() {
                form.find('button:submit').prop('disabled', false);
                showModal($('.js--modal_mail_error'));
            },
        });
    },
};
export default emailConfirm;
