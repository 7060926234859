import modalsCommon from '../modals/modals';

const profile = {
    edit(formId) {
        const $form = $(formId);
        const locale = $form.find('input[name="locale"]').val();
        let data = modalsCommon.getDataFrom($form, ['email', 'name', 'oldPassword', 'password', 'confirmPassword', 'subscribe']);
        data = Object.assign(data, { locale });
        $form.find('button:submit').prop('disabled', true);
        console.log($form);
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            contentType: 'application/json',
            data: JSON.stringify(data),
            success(rsp) {
                if (rsp.status === 'ok') {
                    $form.find('button:submit').prop('disabled', false);
                    if ($('body').hasClass('page--user_licences')) {
                        location.reload();
                    } else {
                        $('.js--modal_updated_success').addClass('active');
                    }
                } else {
                    profile.successMsgHandler($form, rsp);
                }
            },
            error(rsp) {
                profile.errorMsgHandler($form, rsp);
            },
        });
    },
    removeLicenseHandler() {
        $('.js--remove-license').click(function (e) {
            e.preventDefault();
            e.stopPropagation();
            const $link = $(this);
            const url = $link.attr('href');
            const id = $link.attr('data-notApprovedID');
            $.ajax({
                url,
                type: 'POST',
                contentType: 'application/json',
                data:  JSON.stringify({ id }),
                success(rsp) {
                    if (rsp.status === 'ok') {
                        const $modal = $('.js--modal_updated_success');
                        $modal.addClass('active');
                        $modal.find('.js--response').html(rsp.message);
                        $link.closest('.license_content__status').remove();
                    }
                },
            });
        });
    },
    successMsgHandler($form, rsp) {
        const { errors } = rsp;
        errors.forEach(function (err) {
            $form.find('input').filter(function () {
                return this.id.includes(err.property_path);
            })
            .closest('.form_input ').addClass('wrapper_error')
            .find('.form_input__error').append((`<span class="error">${err.message}</span>`)).show();
        });
        $form.find('button:submit').prop('disabled', false);
    },
    errorMsgHandler($form, rsp) {
        $form.find('button:submit').prop('disabled', false);
        $('.js--modal_updated_error').addClass('active');
    },
};

export default profile;
