const customTable = {
    initResponsive() {
        const $table = $('.js--custom_table table');
        if ($table.length > 0) {
            $table.each(function () {
                if ($(this).find('thead').length) {
                    const headerText = [];
                    const headers = this.querySelectorAll('th');
                    const tableBody = this.querySelector('tbody');

                    for (let i = 0; i < headers.length; i += 1) {
                        const current = headers[i];
                        headerText.push(current.textContent.replace(/\r?\n|\r/, ''));
                    }

                    for (let i = 0, row; (row = tableBody.rows[i]); i += 1) {
                        for (let j = 0, col; (col = row.cells[j]); j += 1) {
                            col.setAttribute('data-th', headerText[j]);
                        }
                    }
                }
            });
            $table.find('td').each(function () {
                if ($(this).is(':empty')) {
                    $(this).css('display', 'none');
                }
            });
        }
    },
};

export default customTable;
