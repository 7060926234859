import showModal from '../../containers/modal/modal';

const subscribe = {
    submit($form) {
        const form = $('#subscribe');
        const data = subscribe.getDataFrom($form);
        form.find('button:submit').prop('disabled', true);
        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            contentType: 'application/json',
            data: JSON.stringify(data),
            success(rsp) {
                form.find('button:submit').prop('disabled', false);
                if (rsp.status === 'ok') {
                    $('.js--modal_subscribe_success .modal_container__text').find('p').text(`${rsp.message}`);
                    showModal($('.js--modal_subscribe_success'));
                }
                if (rsp.status === 'error') {
                    $('.js--modal_subscribe_success .modal_container__text').find('p').text(`${rsp.message}`);
                    showModal($('.js--modal_subscribe_success'));
                }
            },
            error(rsp) {
                form.find('button:submit').prop('disabled', false);
                $('.js--modal_subscribe_success .modal_container__text').find('p').text(`${rsp.message}`);
                showModal($('.js--modal_subscribe_success'));
            },
        });
    },
    getDataFrom($form) {
        const inputsAll = $form.find('input, textarea');
        const findInputWith = function (substring) {
            return inputsAll.filter(function () {
                return this.id.includes(substring);
            }, substring);
        };
        const data = {
            email: findInputWith('email').val().trim(),
            gRecaptchaResponse: $('#gRecaptchaResponse').val(),
        };
        return data;
    },
};
export default subscribe;
