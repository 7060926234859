export default (function fbEventsManager() {
    const fbEventsManagerInit = {

        logAddToCartEvent(contentId, contentType, currency, totalPrice) {
            const params = {};

            params[FB.AppEvents.ParameterNames.CONTENT_ID] = contentId;
            params[FB.AppEvents.ParameterNames.CONTENT_TYPE] = contentType;
            params[FB.AppEvents.ParameterNames.NUM_ITEMS] = 1;
            params[FB.AppEvents.ParameterNames.PAYMENT_INFO_AVAILABLE] = 0;
            params[FB.AppEvents.ParameterNames.CURRENCY] = currency;
            FB.AppEvents.logEvent(FB.AppEvents.EventNames.INITIATED_CHECKOUT, totalPrice, params);
        },
        buyEvent() {
            $('.js--price_list_card__link').on('click', function () {
                const urlString = $(this).attr('href');
                const url = new URL(urlString);
                const contentId = url.searchParams.get('products[1][id]');
                const contentType = 'product';
                const currency = $(this).find('meta[itemprop="priceCurrency"]').attr('content');
                const totalPrice = $(this).find('.js--total_price').text();
                fbEventsManagerInit.logAddToCartEvent(contentId, contentType, currency, totalPrice);
            });
        },
        purchaseEvent() {
            if ($('body').hasClass('page--thank_you')) {
                const params = {};

                const purchaseAmount = $('.js--event_data').data('amount')
                const currency = $('.js--event_data').data('currency')

                params['ORDER_ID'] = $('.js--event_data').data('order_id');
                params['ORDER_STATUS'] = $('.js--event_data').data('order_status');
                params['PAYMENT_METHOD_NAME'] = $('.js--event_data').data('payment_method_name');
                params['CUSTOMER_COUNTRY_NAME_BY_IP'] = $('.js--event_data').data('customer_country_name');

                FB.AppEvents.logPurchase(
                  purchaseAmount,
                  currency,
                  params,
                );
            }
        },
    };


    $(document).ready(() => {
        fbEventsManagerInit.buyEvent();
        //fbEventsManagerInit.purchaseEvent();
    });
}());
