import signUp from '../../blocks/modals/sign_up/sign_up';

const modalEvents = {
    pageScrollDisable() {
        $('body').css({ overflow: 'hidden', height: '100%' });
        console.log('body');
    },
    pageScrollEnable() {
        $('body').css('overflow', 'auto');
    },
};

export { modalEvents };

export default function showModal($name) {
    $('.modal').removeClass('active');
    $name.addClass('active');
    // modalEvents.pageScrollDisable();
}

$(() => {
    $('.js--modal_close').click(() => {
        $('.modal').removeClass('active');
        // modalEvents.pageScrollEnable();
    });
    $('.js--modal_overlay').click(() => {
        $('.modal').removeClass('active');
        // modalEvents.pageScrollDisable();
    });
    $('#server_response_container').click(function () {
        $(this).removeClass('show errors success');
    });

    if (window.location.hash.includes('js--modal')) {
        const currentModal = window.location.hash.substring(1);
        switch (currentModal) {
            case 'js--modal_sign_up':
                signUp.showModal();
                break;
            default:
                $('.modal').removeClass('active');
                // modalEvents.pageScrollDisable();
                $(`.${currentModal}`).addClass('active');
                break;
        }
        window.location.hash = '';
    }
});
