// import { modalEvents } from '../../../containers/modal/modal';

import showModal from '../../../containers/modal/modal';

const feedBack = {
    initEvent() {
        $('.js--feedback').click(() => {
            $('.modal').removeClass('active');
            $('.js--modal_feedback').addClass('active');
            // modalEvents.pageScrollDisable();
        });
    },
    submit($form) {
        const data = feedBack.getDataFrom($form);
        $form.find('button:submit').prop('disabled', true);
        $.ajax({
            url: $form.attr('action'),
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(data),
            success(rsp) {
                let $modal;
                if ($form.attr('id') === 'contact_us') {
                    $modal = $('.js--modal_thanks_for_contacts');
                } else {
                    $modal = $('.js--modal_thanks_for_feedback');
                }
                if (rsp.status === 'ok') {
                    $form.find('button:submit').prop('disabled', false);
                    $('.modal.active').removeClass('active');
                    $modal.addClass('active');
                    feedBack.clearForm($form);
                } else {
                    feedBack.showErrorsInForm($form, rsp);
                }
                const params = {};
                params[FB.AppEvents.ParameterNames.REGISTRATION_METHOD] = 'feedback';
                FB.AppEvents.logEvent(FB.AppEvents.EventNames.COMPLETED_REGISTRATION, null, params);
            },
            error(rsp) {
                feedBack.showErrorsInForm($form, rsp);
                $('.modal').removeClass('active');
                $('.js--modal_error_offline').addClass('active');
            },
        });
    },
    getDataFrom($form) {
        const inputsAll = $form.find('input, textarea');
        const findInputWith = function (substring) {
            return inputsAll.filter(function () {
                return this.id.includes(substring);
            }, substring);
        };
        const data = {
            name: findInputWith('name').val().trim(),
            email: findInputWith('email').val().trim(),
            message: findInputWith('message').val().trim(),
            gRecaptchaResponse: $('#gRecaptchaResponse').val(),
        };
        return data;
    },
    clearForm($form) {
        $form.find('input, textarea').val('');
    },
    closeForm() {
        feedBack.clearForm();
        $('.modal').removeClass('active');
    },
    showErrorsInForm($form, rsp) {
        $form.find('button:submit').prop('disabled', false);
        const { errors } = rsp;
        if (typeof errors !== 'undefined') {
            errors.forEach((err) => {
                $form.find('input').each(function () {
                    if ($(this)[0].id.includes(err.property_path)) {
                        const $input = $(this).closest('.form_input');
                        $input.addClass('wrapper_error');
                        $input.find('.form_input__error')
                            .append(
                                (`<span class="error">${err.message}</span>`),
                            )
                            .show();
                    }
                }, err);
                if (err.property_path === 'gRecaptchaResponse') {
                    $form.find('.recaptcha__error').addClass('show');
                    $form.find('.recaptcha__error_text').text(err.message);
                }
            });
        }
    },
};

export default feedBack;
