const companies = {
    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i -= 1) {
            const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // eslint-disable-line
        }
        return array;
    },

    animateCompanies() {
        let columns = 3;
        if (window.matchMedia('(min-width: 768px)').matches) {
            columns = 5;
        }
        let listNumbersCompanies = [];
        const lengthCompanies = $('.js--companies_slider .js--companies_slider__item').length;
        for (let i = 0; i < lengthCompanies; i += 1) {
            listNumbersCompanies.push(i);
        }
        listNumbersCompanies = companies.shuffleArray(listNumbersCompanies);
        for (let i = 0; i < lengthCompanies; i += 1) {
            if (i < columns) {
                const element = $(`.animation_${listNumbersCompanies[i]}`);
                element.css('display', 'list-item');
                element.css('order', i);
                setTimeout(() => element.css('opacity', 1), i * 200);
                setTimeout(() => element.css('opacity', 0), i * 200 + 4500);
            } else {
                $(`.animation_${listNumbersCompanies[i]}`).css('display', 'none');
            }
        }
    },

    initAnimation() {
        companies.animateCompanies();
        setInterval(companies.animateCompanies, 6000);
    },
};
export default companies;
