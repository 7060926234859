const modalsCommon = {
    // Вернет {} с парой "имя поля": "значение"
    getDataFrom($form, fields) {
        const data = {};
        const inputsAll = $form.find('input');
        const inputIncludesID = function (substring) {
            return inputsAll.filter(function () {
                return this.id.includes(substring);
            }, substring);
        };
        if (Array.isArray(fields)) {
            fields.forEach(field => {
                if (field === 'subscribe') {
                    data[field] = inputIncludesID('subscribe').prop('checked');
                } else if (field === 'confirmPassword') {
                    data[field] = inputIncludesID('pass_conf').val();
                } else {
                    data[field] = inputIncludesID(field).val();
                }
            });
        } else {
            console.warn('Не верно переданы данные. Ожидался массив');
        }
        return data;
    },
};

export default modalsCommon;
