const priceList = {
    addHandlers() {
        $('.js--price_during').click(() => {
            $('.list_box').toggleClass('show');
        });
        $(document).click((e) => {
            if (!$('.js--price_during').is(e.target)) {
                $('.list_box').removeClass('show');
            }
        });

        $('.js--change_price_during').click((e) => {
            const $this = $(e.target);
            const $thisParent = $this.closest('.js--price_list_card');
            const $thisLink = $thisParent.find('.js--price_list_card__link');
            const $thisTotalPrice = $thisParent.find('.js--total_price');
            const $thisPriceDuring = $thisParent.find('.js--price_during');

            $thisTotalPrice.text($this.data('price'));
            $thisParent.find('.js--change_price_during').removeClass('list_box__item_active');
            $this.addClass('list_box__item_active');
            $thisPriceDuring.text($this.text());
            $thisLink.attr('href', $this.data('link'));
        });
    },
};

export default priceList;
