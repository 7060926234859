const testimonials = {
    changeSlider() {
        setTimeout(() => {
            const rightBlock = $('.slick-active .js--SliderSlickCompany');
            if (rightBlock) {
                const height = rightBlock.height();
                $('.testimonials .slick-dots').css('top', height);
            }
        }, 100);
        const description = $('.slider_slick__description_text');
        const descriptionWrapper = $('.slider_slick__description_text_wrapper');
        const button = $('.js--testimonials_more');
        if (window.matchMedia('(max-width: 991px)').matches) {
            description.css('max-height', '310px');
            descriptionWrapper.css('max-height', '280px');
            button.html(button.data('more'));
        }
    },
    init() {
        const slickSettings = {
            arrows: true,
            infinite: true,
            fade: true,
            adaptiveHeight: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            appendDots: $('.slider_slick_dots'),
        };
        const slider = $('.js--testimonials__slider');
        slider.on('init', () => {
            testimonials.changeSlider();
            if (window.matchMedia('(max-width: 991px)').matches) {
                $('.slider_slick__description_text').each(function () {
                    if ($(this).outerHeight() > 280) {
                        $(this).closest('.slider_slick__description').addClass('show_more');
                    }
                });
            }
        });

        slider.slick(slickSettings);

        slider.on('beforeChange', () => testimonials.changeSlider());
    },
    addHandlers() {
        $('.js--testimonials_more').click((event) => {
            const button = $(event.currentTarget);
            const descriptionWrapper = button.siblings('.slider_slick__description_text_wrapper');
            const description = descriptionWrapper.find('.slider_slick__description_text');
            const slider = $('.js--testimonials__slider');
            if (typeof button.data('more') === 'undefined'){ button.data('more', button.html()); }
            if (description.css('max-height') === '100%') {
                description.css('max-height', '310px');
                descriptionWrapper.css('max-height', '280px');
                button.html(button.data('more'));
                $('html, body').animate({
                    scrollTop: description.offset().top - $('header').outerHeight() - 15
                }, 800);
                const heightSlide = button.closest('.slider_slick__slide').outerHeight();
                slider.find('.slick-list').css('height', `${heightSlide}px`);
            } else {
                description.css('max-height', '100%');
                descriptionWrapper.css('max-height', '100%');
                button.html(button.data('less'));
                slider.find('.slick-list').css('height', 'fit-content');
            }
        });
    },
};

export default testimonials;
