import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/collapse';
import 'material-design-lite/material';

import 'slick-carousel/slick/slick';
import 'jquery-validation/dist/jquery.validate';
import 'sticky-sidebar/dist/sticky-sidebar';
import 'jquery-mousewheel/jquery.mousewheel';
import 'jscrollpane/script/jquery.jscrollpane.min';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/effect';
import 'jquery-ui/ui/widgets/datepicker';

// Components
import './_helpers/js/sentry';
import customtable from './components/blocks/custom_table/custom_table';
import './components/blocks/hero/hero';
import './pages/article/article';
import './pages/blog/blog';
import companies from './components/blocks/companies/companies';
import './components/blocks/advantage/advantage';
import testimonials from './components/blocks/testimonials/testimonials';
import priceList from './components/blocks/price_list/price_list';
import faq from './components/blocks/faq/faq';
import './components/containers/modal/modal';
import './components/blocks/modals/modals';
import './components/blocks/header/header';
import './components/blocks/demo/demo';
import signUp from './components/blocks/modals/sign_up/sign_up';
import signIn from './components/blocks/modals/sign_in/sign_in';
import feedBack from './components/blocks/modals/feedback/feedback';
import recovery from './components/blocks/modals/recovery/recovery';
import validate from './components/ui/validate';
import utils from './components/ui/utils/utils';
import profile from './components/blocks/profile_content/profile';
import './components/ui/fb_EventsManager/fb_EventsManager';
import './components/ui/scroll_top_btn/scroll_top_btn';
import Cookie from './components/blocks/cookie_info/cookie_info';

$(document).ready(() => {
    profile.removeLicenseHandler();
    utils.clickToCopy();
    utils.initTooltip();
    companies.initAnimation();
    if ($('.page--help').length > 0) {
        faq.init();
    }
    signUp.initEvent();
    signIn.initEvent();
    recovery.initEvent();
    validate.init();
    feedBack.initEvent();
    if (recovery.saveHashIfExist()) {
        recovery.checkRecoveryHash();
    }
    testimonials.addHandlers();
    priceList.addHandlers();
    customtable.initResponsive();
    const cookie = new Cookie($('.js--cookie_info_button'), $('.js--cookie_info'));
    cookie.init();
});
$(window).on('load', () => {
    if ($('.page--main').length > 0 || $('.page--page').length > 0) {
        testimonials.init();
    }
});
