import ClipboardJS from 'clipboard/dist/clipboard.min';

const utils = {
    clickToCopy() {
        const clipboard = new ClipboardJS('.js--copy-to-clip');
        clipboard.on('success', function(e) {
            $(e.trigger).parent().addClass('copied');
            setTimeout(function () {
                $(e.trigger).parent().removeClass('copied');
            }, 3000);
        });
        clipboard.on('error', function(e) {
            console.log(e);
        });
    },
    initTooltip() {
        $('.js--tooltip').tooltip({
            offset: '0, 5',
        });
    },
};

export default utils;
